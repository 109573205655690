
import ActivityStatus from "@/core/constants/ActivityStatus";
import { IActivity } from "@/core/data/activity";
import { ITopic } from "@/core/data/topic";
import ActivityService from "@/core/services/ActivityService";
import TopicService from "@/core/services/TopicService";
import { UploadProps } from "element-plus";
import Swal from "sweetalert2";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { UploadFilled } from "@element-plus/icons-vue";
import { IActivityAttachment } from "@/core/data/activityAttachment";
import { useAuthStore } from "@/store/useAuthStore";
import AddActivityRoomModal from "@/views/studio-lab/components/modals/AddActivityRoomModal.vue";
import { ActivityType } from "@/core/constants/ActivityType";
import { IActivityRoom } from "@/core/data/activityRoom";
interface IUploadFile {
  file: any;
  uid: number;
}
export default defineComponent({
  components: { UploadFilled, AddActivityRoomModal },
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const authStore = useAuthStore();
    const fileList = ref([]);
    const uploadRef = ref();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const activityTypes = Object.values(ActivityType);
    const acceptedTypes = ref(".jpeg,.jpg,.png,.pdf");
    const activityFileTempList = ref<IUploadFile[]>([]);
    const currentTopic = ref<ITopic>();
    const deleteAttachmentIdList = ref<any[]>([]);
    const deleteActivityRoomIdList = ref<any[]>([]);
    const maxFileSize = ref(5 * 1024 * 1024); // Specify the maximum file size in bytes (e.g., 5MB)
    const activityAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activities/attachments/"
    );
    const addActivityRoomModalRef = ref();
    const activityRooms = ref<Array<IActivityRoom>>([]);
    const excludedRoomIds = ref<Array<number>>([]);
    const activityData = ref<IActivity>({
      id: null,
      topic: null,
      title: "",
      description: "",
      type: "",
      status: "",
      expired_date: null,
      createdBy: null,
      attachments: [],
      activity_rooms: [],
    });
    const rules = computed(() => {
      return {
        title: [
          {
            required: true,
            message: "Please input activity title.",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Activity title must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please input activity description.",
            trigger: "blur",
          },
          {
            max: 255,
            message:
              "Activity description must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select activity type.",
            trigger: "change",
          },
        ],
        expired_date: [
          {
            required: true,
            message: "Please input expired date of this activity.",
            trigger: "blur",
          },
        ],
      };
    });
    const initTopic = () => {
      TopicService.getTopicById(route.params.topicId).then((response) => {
        currentTopic.value = response.data;
      });
    };

    const initActivity = () => {
      ActivityService.getActivityById(route.params.activityId).then(
        (response) => {
          activityData.value = response.data;
          initActivityRoom();
        }
      );
    };

    const initActivityRoom = () => {
      activityRooms.value = [];
      activityData.value.activity_rooms?.forEach((activityRoom) => {
        activityRooms.value.push(activityRoom);
        excludedRoomIds.value.push(activityRoom!.room!.id!);
      });
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          var formData = new FormData();

          activityFileTempList.value.forEach((activityFile) => {
            formData.append("attachments[]", activityFile.file);
          });

          activityRooms.value.forEach((activityUser) => {
            formData.append("activityRooms[]", JSON.stringify(activityUser));
          });

          //new activity
          if (activityData.value.id == null) {
            activityData.value.topic = currentTopic.value!;
            activityData.value.status = ActivityStatus.PENDING;
            formData.append("activityData", JSON.stringify(activityData.value));
            ActivityService.createActivity(formData)
              .then((response) => {
                loading.value = false;
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                authStore.setTopicDataChange(true);
                router.back();
              })
              .catch(({ response }) => {
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          } else {
            deleteAttachmentIdList.value.forEach((attachmentId) => {
              formData.append("delete_attachment_id_list[]", attachmentId);
            });
            deleteActivityRoomIdList.value.forEach((activityRoomId) => {
              formData.append("delete_activity_room_id_list[]", activityRoomId);
            });
            formData.append("activityData", JSON.stringify(activityData.value));
            ActivityService.updateActivity(formData)
              .then((response) => {
                loading.value = false;
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                authStore.setTopicDataChange(true);
                router.back();
              })
              .catch(({ response }) => {
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          }
        } else {
          return false;
        }
      });
    };

    const deleteAttachment = (attachment: IActivityAttachment) => {
      deleteAttachmentIdList.value.push(attachment.id);
      let deleteAttachmentIndex = activityData.value.attachments!.findIndex(
        (obj) => obj.id === attachment.id
      );

      activityData.value.attachments!.splice(deleteAttachmentIndex, 1);
    };

    const addParticipant = () => {
      addActivityRoomModalRef.value.showModal();
    };

    const addActivityRoom = (room) => {
      activityRooms.value.push({
        id: null,
        activity: null,
        room: room,
      });
      excludedRoomIds.value.push(room.id);
    };

    const deleteActivityRoom = (activityRoom) => {
      let deleteActivityRoomIndex = activityRooms.value.findIndex(
        (obj) => obj.room!.id === activityRoom.room.id
      );

      if (activityRooms.value[deleteActivityRoomIndex].id != null) {
        deleteActivityRoomIdList.value.push(
          activityRooms.value[deleteActivityRoomIndex].id
        );
      }

      activityRooms.value.splice(deleteActivityRoomIndex, 1);

      excludedRoomIds.value.splice(
        excludedRoomIds.value.indexOf(activityRoom.room.id),
        1
      );
    };

    const goBack = () => {
      router.back();
    };

    const handlePreview = (uploadFile) => {
      if (uploadFile) {
        const isAcceptedType = acceptedTypes.value
          .split(",")
          .some((type) => uploadFile.raw.name.toLowerCase().endsWith(type));
        const isSizeValid = uploadFile.size <= maxFileSize.value;

        if (!isAcceptedType) {
          Swal.fire({
            title: "Warning",
            text: "Only .jpeg, .jpg, .png, .pdf file types are supported.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }

        if (!isSizeValid) {
          Swal.fire({
            title: "Warning",
            text:
              " Maximum file size is " +
              maxFileSize.value / (1024 * 1024) +
              "MB",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (isAcceptedType && isSizeValid) {
          let reader = new FileReader();
          reader.readAsDataURL(uploadFile.raw);
          reader.onload = (e) => {
            activityFileTempList.value.push({
              file: uploadFile.raw,
              uid: uploadFile.uid,
            });
          };
        } else {
          uploadRef.value.handleRemove(uploadFile);
        }
      }
    };

    const handleRemove: UploadProps["onRemove"] = (file, uploadFiles) => {
      let index = activityFileTempList.value.findIndex(
        (obj) => obj.uid === file.uid
      );
      if (index != -1) {
        activityFileTempList.value.splice(index, 1);
      }
    };

    onMounted(() => {
      if (route.params.topicId != undefined) {
        initTopic();
      } else if (route.params.activityId != undefined) {
        initActivity();
      }
      activityData.value.type = ActivityType.INDIVIDUAL;
    });

    return {
      loading,
      formRef,
      activityData,
      rules,
      onSubmit,
      activityTypes,
      initTopic,
      currentTopic,
      acceptedTypes,
      fileList,
      handleRemove,
      activityFileTempList,
      handlePreview,
      uploadRef,
      activityAttachmentbaseURL,
      deleteAttachment,
      addParticipant,
      goBack,
      addActivityRoomModalRef,
      addActivityRoom,
      activityRooms,
      deleteActivityRoom,
      excludedRoomIds,
      initActivityRoom,
    };
  },
});
