
import { IUser } from "@/core/data/user";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { defineComponent, ref, onMounted, computed } from "vue";
import { Modal } from "bootstrap";
import { hideModal } from "@/core/helpers/dom";
import { SearchRoomFilter } from "@/core/filterData/searchRoomFilter";
import RoomService from "@/core/services/RoomService";
export default defineComponent({
  components: {
    Datatable,
  },
  emit: ["onAddBtnClick"],
  props: {
    excludedRoomIds: Array<number>,
  },
  setup(props, { emit }) {
    let thisModalObj;
    const search = ref<string>("");
    var tableData = ref<Array<IUser>>([]);
    var totalData = ref(0);
    var currentPage = ref(1);
    var rowsPerPage = ref(10);
    const addActivityRoomModalRef = ref<null | HTMLElement>(null);
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: false,
      },
      {
        name: "",
        key: "actions",
      },
    ]);

    const initRoomData = () => {
      var searchRoomFilter: SearchRoomFilter = {
        page: 1,
        size: 10,
        text_search: search.value,
      };
      if (props.excludedRoomIds!.length > 0) {
        searchRoomFilter.room_exclude = props.excludedRoomIds;
      }
      RoomService.getAllRooms(searchRoomFilter).then((response) => {
        totalData.value = response.data.total;
        tableData.value.splice(
          0,
          tableData.value.length,
          ...response.data.data
        );
      });
    };

    const searchItems = () => {
      initRoomData();
    };

    const assignRoom = (user) => {
      emit("onAddBtnClick", user);
      hideModal(addActivityRoomModalRef.value);
    };

    onMounted(() => {
      thisModalObj = new Modal(addActivityRoomModalRef.value);
      //   var userModal = document.getElementById("kt_modal_new_user");
      //   userModal!.addEventListener("hidden.bs.modal", function () {
      //     resetModal();
      //   });
    });

    const onPageChange = (pageNumber) => {
      currentPage.value = pageNumber;
      initRoomData();
    };

    const showModal = () => {
      thisModalObj.show();
      initRoomData();
    };

    return {
      search,
      tableHeader,
      tableData,
      totalData,
      currentPage,
      rowsPerPage,
      addActivityRoomModalRef,
      showModal,
      onPageChange,
      initRoomData,
      searchItems,
      assignRoom,
    };
  },
});
